import pdf from "../Documents/CV_Neupane.pdf";

const CV = () => {
  return (
    <>
      <div
        style={{
          display: "flex",
        }}
      >
        <object
          width="1600px"
          height="1000px"
          type="application/pdf"
          data={pdf}
        ></object>
      </div>
    </>
  );
};
export default CV;

import profilePicture from "../MyPhoto/IMG-20231031-WA0000.jpg";
import "../Styles/MyInfo.css";
const MyInfo = () => {
  return (
    <>
      <div className="my-name-picture">
        <div>
          <img className="my-picture" src={profilePicture}></img>
        </div>
        <div
          className="my-detail
        "
        >
          <div>
            <span className="my-name">Tank Prasad Neupane</span>
            <br />
            <br />
            <br />
          </div>
          <div>
            <span>Lecturer of Marketing at O.P Jindal Global University</span>
            <br />
            <br />
          </div>
          <div>
            <span> Sector-35, Sonipat, Haryana</span>
            <br />
            <br />
          </div>
          <div>
            <span>tankp.neupane@jgu.edu.in</span>
          </div>
        </div>
      </div>
      <br />
      <div>
        <p className="my-bio">
          Tank Prasad Neupane is a Lecturer in Marketing at O. P. Jindal Global
          University (An Institute of Eminence Deemed to be University),
          Haryana. Before joining this university, he worked with reputed
          institutions like Tribhuvan University and Kathmandu University,
          Nepal. He has about eight years of research experience and 17 years of
          teaching experience. His key teaching areas are marketing, industrial
          and organizational psychology, operations research, and data
          analytics.
          <br />
          <br /> With over eight years of professional experience, he has
          offered technical advice to the government of Nepal and donors on the
          impact study of developmental programs at the federal, provincial, and
          local levels governments in Nepal. He has led a few government-funded
          technical studies as principal investigator &amp; co-investigator and
          provided inputs to the Government of Nepal on key policy and planning
          programs.
          <br />
          <br /> He has peer-reviewed research papers for Review of Behavioral
          Economics- an ABDC &#39;B&#39;, ABS- 2, a Web of Science indexed
          Journal; Agricultural Economics Review- a SCOPUS and ABDC &#39;C&#39;
          listed journal, Euro-Med Journal of Business- Emerald Publishing- an
          S.J.R. Q1 Journal, Journal of Business and Management Research, The
          Lumbini Journal of Business and Economics.
        </p>
      </div>
    </>
  );
};
export default MyInfo;

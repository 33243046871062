import Navbar from "./Navbar";
const Research = () => {
  return (
    <>
      <hr />
      <Navbar />
      <div style={{ display: "flex", margin: "30px" }}>
        <a
          target="blank"
          href="https://www.nowpublishers.com/article/Details/RBE-0126"
        >
          {" "}
          Neupane, T.P. (2020). Is Individualism Fatal in Pandemic? Review of
          Behavioral Economics, 7, 1-16. U.S.A.: Now Publisher. "Lead Paper,"
        </a>
      </div>
    </>
  );
};

export default Research;

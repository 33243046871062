import MyInfo from "./MyInfo";
import Navbar from "./Navbar";

const Home = () => {
  return (
    <>
      <hr />
      <Navbar />
      <MyInfo />
      <hr />
    </>
  );
};
export default Home;

import { BrowserRouter, Routes, Navigate, Route } from "react-router-dom";
import Home from "../pages/Home";
import CV from "../pages/Cv";
import Research from "../pages/Research";
import PopularWriting from "../pages/PopularWriting";

const Router = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" Component={Home} />
        <Route path="/cv" Component={CV} />
        <Route path="/research" Component={Research} />
        <Route path="/popularWriting" Component={PopularWriting} />
      </Routes>
    </BrowserRouter>
  );
};

export default Router;

import Navbar from "./Navbar";
const PopularWriting = () => {
  return (
    <>
      <hr />
      <Navbar />
      To be added soon....
    </>
  );
};
export default PopularWriting;

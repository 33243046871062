import { Link } from "react-router-dom";
import "../Styles/Navbar.css";
const Navbar = () => {
  return (
    <>
      <div>
        <ul className="nav-bar">
          <Link className="link-text" to="/">
            Home
          </Link>
          <Link className="link-text" to="/cv">
            CV
          </Link>
          <Link className="link-text" to="/research">
            Research
          </Link>
          <Link className="link-text" to="/popularWriting">
            Popular Writing
          </Link>
        </ul>
      </div>
    </>
  );
};
export default Navbar;
